import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
// import Header from "../../../layouts/Header";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Tippy from "@tippyjs/react";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { saveAs } from "file-saver"; // For CSV export
import jsPDF from "jspdf"; // For PDF export
import { Dropdown, Menu, Button } from "antd";
import 'tippy.js/dist/tippy.css'; // Optional: for default styles

function AdminUsbVpage() {
  const navigate = useNavigate();
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const location = useLocation();

  const [skin, setSkin] = useState(currentSkin);
  const [columnData, setColumnData] = useState([]);
  console.log(columnData, "columnData");
  const [loading, setLoading] = useState(true);
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [pcId, setPcId] = useState([]);
  const [serialNumbers, setSerialNumbers] = useState([""]);
  const [toggleClearRows, setToggleClearRows] = useState(false);
  const [state, setState] = useState(""); // For the state dropdown
  const [comment, setComment] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedPcId, setSelectedPcId] = useState("");
  const [selectedServiceNo, setSelectedServiceNo] = useState("");
  const [selectedUsbHistoryId, setSelectedUsbHistoryId] = useState("");
  const [adminActionData, setAdminActionData] = useState();

  console.log(adminActionData, "adminActionData");
  const [activeRow, setActiveRow] = useState(null); // State to track which serial number is clicked
  const { user } = useSelector((state) => state.authSlice);

  const handlePageChange = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  const handlePageSizeChange = (pageSize) => {
    setPageSize(pageSize);
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LINUX_URL}/usbhistoryinfo/get-pc-with-whitelisted-usb/${user?.data?.user?.userId}?page=${pageNumber}&pageSize=${pageSize}`,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      setTotalDocuments(response?.data?.pagination?.totalDocuments);
      setColumnData(response.data.data); // Adjusted to set the correct data
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data: ", error.response);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [user, pageNumber, pageSize]);

  const actionsEnum = [
    "actionInitiated",
    "closeViolation",
    "updateViolation",
    "usbLink",
    "usbDelink",
    "falsePositive",
    "falseNegative",
  ];

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };

  const handleStatusChange = async (userId, pcId, serviceno) => {
    console.log("ddddd:", userId);

    try {
      const data = {
        falsePositive: false,
        serviceNo: serviceno,
        pcId: pcId,
      };

      const response = await axios.put(
        `${process.env.REACT_APP_LINUX_URL}/usbhistoryinfo/update-false-positive/${userId}/${user?.data?.user?.userId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      console.log(response.data, "rrrrrrrrrr");

      if (response.data.status === true) {
        toast.success(response?.data?.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        fetchData();
        // window.location.reload();
      } else {
        toast.error("Failed to update status");
      }
    } catch (error) {
      // toast.error("Error updating status");
      console.error("Error updating status:", error);
    }
  };

  const handleSerialClick = (row) => {
    // Set the active row when serial number is clicked
    setActiveRow(row);
  };

  // Function to render the dropdown menu with USB Vendor and Product IDs
  const menu = (row) => (
    <Menu>
      {row.usbName && (
        <Menu.Item key="usbName">
          <strong>USB Name:</strong> {row.usbName}
        </Menu.Item>
      )}
      {row.usbProductId && (
        <Menu.Item key="productId">
          <strong>Product ID:</strong> {row.usbProductId}
        </Menu.Item>
      )}
      {row.usbVendorId && (
        <Menu.Item key="vendorId">
          <strong>Vendor ID:</strong> {row.usbVendorId}
        </Menu.Item>
      )}
    </Menu>
  );

  const columns = [
    // {
    //   name: "PC ID",
    //   selector: (row) => row.pcId || "N/A",
    //   sortable: true,
    // },
    {
      name: "Hostname",
      selector: (row) => row.hostname || "N/A",
      sortable: true,
      width: "200px",
    },
    {
      name: "Serial Number",
      selector: (row) => row.serialNumber || "N/A",
      sortable: true,
      width: "180px",
    },
    {
      name: "IP Address",
      selector: (row) => row.ipAddress || "N/A",
      sortable: true,
      width: "180px",
    },
    {
      name: "Timestamp",
      selector: (row) => row.time || "N/A",
      sortable: true,
      width: "210px",
    },

    {
      name: "USB Manufacturer",
      selector: (row) => row.usbManufacturer || "N/A",
      sortable: true,
      width: "180px",
    },

    {
      name: "USB Serial Number",
      selector: (row) => (
        <Tippy content={row.usbSerialNumber}>
          {/* Dropdown for USB details on serial click */}
          <Dropdown
            overlay={menu(row)}
            trigger={["click"]} // Trigger dropdown on click
            visible={activeRow === row} // Show dropdown only for the clicked row
            onVisibleChange={(visible) => !visible && setActiveRow(null)} // Close dropdown on outside click
          >
            <div onClick={() => handleSerialClick(row)}>
              <Button type="link">
                <span> {row.usbSerialNumber}</span>
                {/* <i className="fa-solid fa-angle-down"></i> */}
              </Button>
            </div>
          </Dropdown>
        </Tippy>
      ),
      sortable: true,
      width: "220px",
    },
    // {
    //   name: "Timestamp",
    //   selector: (row) => formatTimestamp(row.updatedAt) || "N/A",
    //   sortable: true,
    //   width: "210px",
    // },
    {
      name: "Interface Type",
      selector: (row) => row.interfaceType || "N/A",
      sortable: true,
      width: "180px",
    },

    {
      name: "Admin",
      selector: (row) => row.serviceNo || "N/A",
      sortable: true,
      width: "180px",
    },
    // {
    //   name: "Admin Updated At",
    //   selector: (row) => formatTimestamp(row.AdminUpdatedAt || "N/A"),
    //   sortable: true,
    //   width: "180px",
    // },
    {
      name: "Admin Updated At",
      selector: (row) => (
        <Tippy content="Last updated by Admin" placement="top">
          <span>{formatTimestamp(row.AdminUpdatedAt || "N/A")}</span>
        </Tippy>
      ),
      sortable: true,
      width: "180px",
    },
    
    // {
    //   name: "Product Id",
    //   selector: (row) => row.usbProductId || "N/A",
    //   sortable: true,
    //   width: "180px",
    // },
    // {
    //   name: "Vendor Id",
    //   selector: (row) => row.usbVendorId || "N/A",
    //   sortable: true,
    //   width: "180px",
    // },

    {
      name: "Action",
      cell: (row) => (
        <>
          <button
            type="button"
            // className="btn btn-sm btn-outline-dark"
            className={`btn btn-sm ${
              row?.falsePositive === false ? "btn-outline-dark" : "btn-dark"
            }`}
            style={{
              width: "8rem",
              textAlign: "center",
              marginRight: "2rem",
            }} // Add margin-right for spacing
            onClick={() =>
              handleStatusChange(
                row.usbHistoryId,
                row?.pcId,
                row.usbSerialNumber
              )
            } // Use an arrow function to pass the parameters
            disabled={row?.falsePositive === false}
          >
            {" "}
            False Negative{" "}
          </button>

          <button
            type="button"
            // class="btn btn-sm btn-outline-info"
            className={`btn btn-sm ${
              row?.is_Linked === false ? "btn-outline-info" : "btn-info"
            }`}
            style={{ width: "6rem", textAlign: "center", marginRight: "2rem" }}
            onClick={(e) => handleSubmit(e, row?.pcId, row?.usbSerialNumber)} // Use an arrow function to pass the parameters
            disabled={row?.is_Linked === false}
          >
            {" "}
            Un-whitelist
          </button>

          {/* btn-outline-primary  for disible mode set this class */}
          <div style={{ position: "relative" }}>
            <button
              type="button"
              className="btn btn-sm btn-outline-primary position-relative"
              style={{ width: "10rem", textAlign: "center" }}
              id="openModalButton"
              data-bs-toggle="modal"
              data-bs-target="#deviceModal"
              onClick={() => {
                console.log("Button clicked");
                handleOpenModal(row?.pcId, row?.usbHistoryId);
              }}
              disabled={
                row?.IsadminActionDone === "completed" ||
                row?.IsadminActionDone == "inProgress" ||
                row?.IsadminActionDone == "pending"
              }
            >
              Admin Action
            </button>

            <button
              type="button"
              // className="btn btn-sm btn-danger position-absolute"
              className={`btn btn-sm position-absolute ${
                row?.IsadminActionDone === "pending"
                  ? "btn-danger"
                  : row?.IsadminActionDone === "inProgress"
                  ? "btn-warning"
                  : row?.IsadminActionDone === "completed"
                  ? "btn-success"
                  : "btn-dark"
              }`}
              id="openModalButton"
              data-bs-toggle="modal"
              data-bs-target="#deviceModalCount"
              disabled={row?.adminActionCount === 0}
              style={{
                top: "-10px",
                right: "-11px",
                height: "22px", // Height and width should be equal for a circle
                width: "22px", // Set width to match height
                borderRadius: "50%", // Make it circular
                display: "flex", // Use flexbox to center text
                alignItems: "center", // Center vertically
                justifyContent: "center", // Center horizontally
                padding: 0, // Remove default padding
              }}
              onClick={() => handleOpenModalCount(row?.pcId, row?.usbHistoryId)}
            >
              <span style={{ fontSize: "13px" }}>{row?.adminActionCount}</span>
            </button>
          </div>
        </>
      ),
      sortable: false,
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "#cccccc",
      },
    },
  };

  const exportToPDF = (data, filename = "Whitelist_USB.pdf") => {
    const doc = new jsPDF("landscape"); // Set landscape mode

    // Organization name and export date/time
    const orgName = user?.data?.user?.organization || "Organization Name";
    const exportDateTime = formatTimestamp(new Date());

    // Add header with organization name on the left and export date/time on the right
    doc.setFontSize(15);
    doc.text(`Organization: ${orgName}`, 14, 15); // Left-aligned
    doc.text(
      `Exported: ${exportDateTime}`,
      doc.internal.pageSize.getWidth() - 14,
      15,
      { align: "right" }
    ); // Right-aligned

    // Add title
    doc.setFontSize(15);
    doc.text("Whitelist USB", 14, 22);

    const startYPosition = 30; // Start position for the table
    const headers = [
      "Hostname",
      "Serial Number",
      "IP Address",
      "Time",
      "USB Manufacturer",
      "USB Serial Number",
      "Interface Type",
      "Service No",
      "Admin Updated At",
      "USB Name",
      "Vendor ID", // Updated header
      "Product ID", // Updated header
    ];

    doc.autoTable({
      startY: startYPosition,
      head: [headers],
      body: columnData.map((item) => [
        item.hostname || "N/A",
        item.serialNumber || "N/A",
        item.ipAddress || "N/A",
        item.time || "N/A",
        item.usbManufacturer || "N/A",
        item.usbSerialNumber || "N/A",
        item.interfaceType || "N/A",
        item.serviceNo || "N/A",
        formatTimestamp(item.AdminUpdatedAt || "N/A"),
        item.usbName || "N/A",
        item.usbVendorId || "N/A",
        item.usbProductId || "N/A",
      ]),
      styles: {
        cellPadding: 1, // Further reduced padding for better fit
        lineColor: [44, 62, 80], // Border color (RGB)
        lineWidth: 0.1, // Border width
      },
      headStyles: {
        fillColor: [52, 73, 94], // Header background color
        textColor: 255, // Header text color
        halign: "center", // Center align header text
        lineWidth: 0.5, // Border width for header
        fontSize: 9, // Further reduced font size for headers
        fontStyle: "bold", // Bold font style for headers
      },
      bodyStyles: {
        lineColor: [44, 62, 80], // Row border color
        lineWidth: 0.1, // Border width for rows
        fontSize: 8, // Slightly smaller font for body text
      },
      alternateRowStyles: {
        fillColor: [240, 240, 240], // Background color for alternate rows
      },
      // columnStyles: {
      //   // Adjust column widths to fit content better
      //   0: { cellWidth: 30 }, // Hostname
      //   1: { cellWidth: 25 }, // Serial Number
      //   2: { cellWidth: 25 }, // IP Address
      //   3: { cellWidth: 20 }, // Time
      //   4: { cellWidth: 30 }, // USB Manufacturer
      //   5: { cellWidth: 30 }, // USB Serial Number
      //   6: { cellWidth: 15 }, // Interface Type
      //   7: { cellWidth: 20 }, // Service No
      //   8: { cellWidth: 30 }, // Admin Updated At
      //   9: { cellWidth: 30 }, // USB Name
      //   10: { cellWidth: 25 }, // Vendor ID
      //   11: { cellWidth: 25 }, // Product ID
      // },
    });

    doc.save(filename);
  };

  const exportToCSV = (rows, filename = "whitelist_USB.csv") => {
    const orgName = user?.data?.user?.organization || "Organization Name";
    const exportDateTime = formatTimestamp(new Date());

    // Header with organization name and export date/time
    const headerInfo = [
      `Organization: ${orgName}`,
      `Exported: ${exportDateTime}`,
    ];

    // Table headers
    const headers = [
      "Hostname",
      "Serial Number",
      "IP Address",
      "Time",
      "USB Manufacturer",
      "USB Serial Number",
      "Interface Type",
      "Service No",
      "Admin Updated At",
      "USB Name",
      "Vendor ID", // Updated header
      "Product ID", // Updated header
    ];

    const csvRows = columnData.map((item) => [
      item.hostname || "N/A",
      item.serialNumber || "N/A",
      item.ipAddress || "N/A",
      item.time || "N/A",
      item.usbManufacturer || "N/A",
      item.usbSerialNumber || "N/A",
      item.interfaceType || "N/A",
      item.serviceNo || "N/A",
      formatTimestamp(item.AdminUpdatedAt || "N/A"),
      item.usbName || "N/A",
      item.usbVendorId || "N/A",
      item.usbProductId || "N/A",
    ]);

    // Combine all CSV content
    const csvContent = [
      headerInfo, // Add organization name and export date/time
      headers, // Add the table headers
      ...csvRows, // Add the data rows
    ]
      .map((e) => e.join(",")) // Join each array into a comma-separated string
      .join("\n"); // Join each row with a new line

    // Create a Blob and download link for the CSV file
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");

    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleSerialNumberChange = (index, value) => {
    const newSerialNumbers = [...serialNumbers];
    newSerialNumbers[index] = value;
    setSerialNumbers(newSerialNumbers);
  };
  const addSerialNumberField = () => {
    setSerialNumbers([...serialNumbers, ""]);
  };

  const handleSelectedRowsChange = ({ selectedRows }) => {
    const pcIds = selectedRows.map((row) => row.pcId);
    const serialNumbers = selectedRows.map((row) => row.serialNumber);
    setPcId(pcIds);
    setSerialNumbers(serialNumbers);

    console.log(pcId, "pcIds");
  };

  const handleOpenModal = (pcId, usbHistoryId) => {
    setSelectedPcId(pcId); // Store pcId in state
    setSelectedUsbHistoryId(usbHistoryId); // Store usbHistoryId in state
    // setSelectedServiceNo(serviceNo); // Store serviceNo in state
    HandleAdminget(usbHistoryId);
  };

  const handleOpenModalCount = (pcId, usbHistoryId) => {
    setSelectedPcId(pcId); // Store pcId in state
    setSelectedUsbHistoryId(usbHistoryId); // Store usbHistoryId in state
    // setSelectedServiceNo(serviceNo); // Store serviceNo in state
    HandleAdminget(usbHistoryId);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const getBadgeClass = (action) => {
    switch (action) {
      case "actionInitiated":
        return "text-bg-danger";
      case "updateViolation":
        return "text-bg-warning";
      case "closeViolation":
        return "text-bg-success";
      default:
        return "text-bg-secondary"; // fallback class
    }
  };

  // Inside your component render
  const action =
    Array.isArray(adminActionData) && adminActionData?.length > 0
      ? adminActionData[0]?.action
      : null;

  const badgeClass = getBadgeClass(action);

  const handleSubmit = async (e, pcId, serialNumbers) => {
    e.preventDefault();
    try {
      const data = {
        organization_Id: user?.data?.user?.organizationId,
        // pcIds: pcId.map((item) => item.pc_Id),
        // usbSerialNumbers: serialNumbers.filter((sn) => sn.trim() !== ""), // Filter out any empty values
        pcIds: [pcId],
        usbSerialNumbers: [serialNumbers],
        serviceNo: user?.data?.user?.email,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_NOTIFY_URL}/de-link/usbs/to-devices/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );

      if (response.data.status === true) {
        toast.success(response?.data?.message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        // Optionally reset the form here
        // setSerialNumbers(['']);
        fetchData();
        // document.getElementById("closeModal").click();
        // setToggleClearRows(!toggleClearRows); // Toggle clear rows
        // setSerialNumbers([]);
      } else {
        toast.error("Failed to update status");
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const handleSubmitControl = async (e) => {
    e.preventDefault();
    try {
      const data = {
        action: state,
        comment: comment,
        usbHistoryId: selectedUsbHistoryId,
        serviceNo: user?.data?.user?.email,
        pcId: selectedPcId,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_NOTIFY_URL}/create-admin-action/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );

      if (response?.data?.message?.length > 0) {
        toast.success(response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        // Optionally reset the form here

        // document.getElementById("closeModal").click();

        // setSerialNumbers([]);
        setState(""); // Reset the state
        setComment(""); // Reset the comment
        HandleAdminget(selectedUsbHistoryId);
        fetchData();
      } else {
        toast.error("Failed to update status");
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const HandleAdminget = async (usbHistoryId) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_NOTIFY_URL}/get-admin-action/by/${usbHistoryId}/${user?.data?.user?.userId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      setAdminActionData(response?.data?.data);

      if (response.data.status === true) {
        toast.success(response?.data?.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        // Optionally reset the form or fetch updated data
      } else {
        toast.error("Failed to de-link USBs from devices");
      }
    } catch (error) {
      console.error("Error de-linking USBs from devices:", error);
      toast.error("Error de-linking USBs from devices");
    }
  };

  //   const isActionMatched = (array, actions) => {
  //     return array.some(item => actions.includes(item.action));
  // };

  // const result = isActionMatched(adminActionData, actionsEnum);
  // console.log(result,'ggggggggggggggg'); // Output: true

  const isActionMatched = (array, action) => {
    return array?.some((item) => item.action === action);
  };

  const falsePositiveResult = isActionMatched(adminActionData, "falsePositive");
  const falseNegativeResult = isActionMatched(adminActionData, "falseNegative");

  console.log(falsePositiveResult, "falsePositiveResult"); // true
  console.log(falseNegativeResult, "falseNegativeResult"); // false

  const columnsAdmin = [
    {
      name: "S.No",
      selector: (row, index) => index + 1, // Automatically generate serial numbers
      sortable: true,
      width: "90px",
    },
    {
      name: "Date",
      selector: (row) => {
        if (!row.createdAt) return null; // Don't show anything if createdAt is empty
        return (
          <Tippy content={formatTimestamp(row.createdAt)}>
            <div>{formatTimestamp(row.createdAt)}</div>
          </Tippy>
        );
      },
      sortable: true,
      width: "160px",
    },
    // {
    //   name: "Date",
    //   selector: (row) => row.createdAt,
    //   sortable: true,
    // },
    {
      name: "State",
      selector: (row) => row.action,
      sortable: true,
      width: "130px",

      // cell: (row) => {
      //   let badgeClass = "";
      //   let badgeText = "";

      //   switch (row.action) {
      //     case "actionInitiated":
      //       badgeClass = "text-bg-danger";
      //       badgeText = "actionInitiated";
      //       break;
      //     case "updateViolation":
      //       badgeClass = "text-bg-warning";
      //       badgeText = "updateViolation";
      //       break;
      //     case "closeViolation":
      //       badgeClass = "text-bg-success";
      //       badgeText = "closeViolation";
      //       break;
      //     default:
      //       badgeClass = "text-bg-secondary"; // fallback class
      //       badgeText = "unknown action";
      //   }

      //   return (
      //     <span className={`badge rounded-pill ${badgeClass}`}>
      //       {badgeText}
      //     </span>
      //   );
      // },
    },
    // {
    //   name: "State",
    //   selector: (row) => row.action,
    //   sortable: true,
    // },

    {
      name: "Comment",
      selector: (row) => (
        <Tippy content={row.comment} placement="top">
          <span>{row.comment}</span>
        </Tippy>
      ),
      sortable: true,
    },
  ];
  return (
    <React.Fragment>
      {/* <Header onSkin={setSkin} /> */}
      <div
        className="main main-app p-3 p-lg-4"
        style={{ backgroundColor: "#ECF9FF" }}
      >
        <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded cardStyle">
          <span className="d-flex align-items-start justify-content-between fs-sm-normal mb-1 ps-2 d-flex flex-column">
            <p className="fs-20 fw-bolder text-dark  p-0 m-0">
              Whitelist <i className="fa-brands fa-usb"></i>
            </p>
          </span>
          <div className="d-flex align-items-center justify-content-between gap-2">
            <button
              type="button"
              className="btn btn-primary text-white"
              onClick={() => exportToPDF(columnData)}
            >
              <i className="fa-solid fa-file-pdf"></i> &nbsp; PDF
            </button>

            <button
              type="button"
              className="btn btn-success text-white"
              onClick={() => exportToCSV(columnData)}
            >
              <i className="fa-solid fa-file-excel"></i> &nbsp; CSV
            </button>
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded cardStyle">
          <div className="col-xl">
            <p className="fs-18 fw-bolder p-0 m-0">USB Lists</p>
            <p className="text-danger p-0 m-0 fw-semibold">
              Total USB: {totalDocuments}
            </p>
          </div>
          {/* <button
            type="button"
            id="openModalButton"
            className="btn btn-secondary text-white"
            data-bs-toggle="modal"
            data-bs-target="#deviceModal"
            disabled={pcId.length === 0}
          >
            Action De-Link<i class="ri-links-fill"></i>
          </button> */}
        </div>

        <div className="card rounded cardStyle">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <DataTable
              columns={columns}
              data={columnData}
              pagination
              customStyles={customStyles}
              selectableRowsHighlight
              highlightOnHover
              paginationServer
              paginationTotalRows={totalDocuments}
              paginationDefaultPage={pageNumber}
              paginationPerPage={pageSize}
              // selectableRows
              // clearSelectedRows={toggleClearRows}
              onSelectedRowsChange={handleSelectedRowsChange}
              onChangePage={(page) => {
                handlePageChange(page);
              }}
              onChangeRowsPerPage={(newPageSize) => {
                handlePageSizeChange(newPageSize);
              }}
              noDataComponent={<p className="p-0 m-2 me-auto">No Data Found</p>}
            />
          )}
        </div>
      </div>
      {/* <ToastContainer /> */}
      <div
        className="modal fade"
        id="deviceModal"
        tabIndex="-1"
        aria-labelledby="deviceModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="deviceModalLabel">
                Control <i className="fa-brands fa-usb"></i>
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="closeModal"
              ></button>
            </div>
            <div className="m-3">
              <div className=" card allCardEffect shadow mt-2">
                <form onSubmit={(e) => handleSubmitControl(e, state, comment)}>
                  <div className="modal-body">
                    {/* Dropdown for state selection */}
                    <div className="mb-3">
                      <label htmlFor="stateSelect" className="form-label">
                        State
                      </label>
                      <select
                        id="stateSelect"
                        className="form-select"
                        value={state} // Bind the value to the state
                        onChange={(e) => setState(e.target.value)}
                        required
                      >
                        <option value="">Select State</option>
                        <option value="actionInitiated">
                          Action Initiated
                        </option>
                        <option value="updateViolation">
                          Violation Updated
                        </option>
                        <option value="closeViolation">Violation Closed</option>
                      </select>
                    </div>

                    {/* Comment textarea */}
                    <div className="mb-3">
                      <label htmlFor="comment" className="form-label">
                        Comment
                      </label>
                      <textarea
                        id="comment"
                        className="form-control"
                        rows="3"
                        value={comment} // Bind the value to the comment
                        onChange={(e) => setComment(e.target.value)}
                        placeholder="Add your comment here..."
                        required
                      ></textarea>
                    </div>
                  </div>

                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-sm btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button type="submit" className="btn btn-sm btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>

              <div className=" card allCardEffect shadow mt-2 mb-2">
                <DataTable
                  columns={columnsAdmin}
                  data={adminActionData}
                  customStyles={customStyles}
                  pagination
                  noDataComponent={
                    <p className="p-0 my-2  text-center"> No Data Found </p>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <div
          className="modal fade"
          id="deviceModalCount"
          tabIndex="-1"
          aria-labelledby="deviceModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="deviceModalLabel">
                  Admin Actions Histroy
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="card allCardEffect shadow mt-2 mb-2">
                  <DataTable
                    columns={columnsAdmin} // Replace with your actual column definitions
                    data={adminActionData} // Replace with your actual data
                    customStyles={customStyles} // Optional: your custom styles
                    pagination
                    noDataComponent={
                      <p className="p-0 my-2 text-center">No Data Found</p>
                    }
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                {/* <button type="button" className="btn btn-primary">Save changes</button> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default AdminUsbVpage;
